@font-face {
  font-family: "Raleway";
  src: url("./fonts/Raleway-Regular.ttf");
}
@font-face {
  font-family: "DejaVu Sans";
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf")
    format("truetype");
}

body {
  /* width: 100vw; */
  margin: 0;
  color: #515c6f;
  font-family: Microsoft Tai Le, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: overlay;
}
::-webkit-scrollbar-thumb {
  background: #c1c1c1;
}
::-webkit-scrollbar {
  width: 0.6em;
}
.text-dark {
  color: #000 !important;
}
.messages-height {
  height: 25rem !important;
  overflow: scroll !important;
}
.pdf-header-details {
  margin-bottom: 15px !important;
}
.home-btns {
  /* padding: 15px; */
}
.mob-col-width {
  max-width: 100% !important;
  padding-right: 0px !important;
}
.top-rectangle {
  background: #495057;
  transform: rotate(-40deg);
}

.pdf-input-field-title {
  margin-right: 25px;
}
.pdf-input-field-title-date {
  margin-right: 38px;
}
.date-margin {
  margin-right: 72px !important;
}
.date-margin-bill {
  margin-right: 128px !important;
}
.pdf-input-field {
  font-weight: bold;
  height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  display: block;
  width: 10rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.myBtn {
  position: absolute;
  bottom: 100px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: rgba(85, 85, 85, 0.734);
  color: white;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
}
kendo-pdf-document {
  font-family: "Raleway" !important;
}
.myBtn:hover {
  background-color: #555;
}
.pond {
  display: flex;
  justify-content: center;
  align-items: center;
}
.badge {
  border-radius: 50% !important;
}
.badge-notification {
  position: relative;
  left: 23%;
  bottom: 34%;
  top: -34px;
  border-radius: 35%;
  width: 3px;
  height: 3px;
}

.page-height {
  min-height: 75vh !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.app-width {
  max-width: 430px !important;
  margin: auto;
}
.link-style {
  text-decoration: none;
  color: #515c6f;
}
.header-height {
  max-height: 50px;
}

.link-style:hover {
  text-decoration: none;
  color: #515c6f;
}
.btn-primary {
  height: 39px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.close-modal {
  margin-left: -25px !important;
  margin-top: -4px !important;
}
/*
.btn-primary:hover {
  background-color: #515c6f !important;
}
.btn-primary:focus {
  background-color: #515c6f !important;
}
.btn-primary:active {
  background-color: #515c6f !important;
} */
.btn {
  font-size: 16px !important;
  font-weight: bold;
  white-space: normal !important;
}
.btn-outline-secondary {
  color: #515c6f !important;
  background-color: white !important;
}
.btn-success {
  background-color: #267639 !important;
}
.react-tel-input .flag-dropdown.open {
  width: 100% !important;
}
.react-tel-input .country-list {
  width: 101% !important;
  height: 100px !important;
}
.react-datepicker-wrapper {
  width: 100% !important;
  z-index: 11111111 !important;
}

/* Floatting field */

input.fullname,
textarea.fullname {
  transition: all 0.2s;
  touch-action: manipulation;
}

label.fullname {
  margin: 0;
  color: #6c757d;
}

/* input.fullname-modified,
textarea.fullname-modified {
  padding: 5px;
  cursor: text;
  width: 100%;
  border: 2px solid #848c9a;
 
  border-radius: 5px;
  background: none;
  z-index: 5;
} */
input.fullname,
textarea.fullname {
  padding: 15px;
  cursor: text;
  width: 100%;
  border: 2px solid #848c9a;
  border-radius: 5px;
  background: none;
  z-index: 5;
}
select.fullname {
  padding: 15px;
  cursor: text;
  width: 100%;
  border: 2px solid #848c9a;
  border-radius: 5px;
  background: none;
  z-index: 5;
}
input.field-error,
textarea.field-error {
  border: 2px solid #ff0000 !important;
}
input.fullname:focus,
textarea.fullname:focus {
  outline: 0;
  /* z-index: 0; */
}
input.fullname:placeholder-shown + label.fullname {
  cursor: text;
  max-width: max-content;
  transform-origin: left bottom;
  transform: translate(0, 2.7rem) scale(1.5);
  margin-left: 15px;
  font-size: 12px;
}
.field-margin {
  margin-left: 40px;
}
textarea.fullname:placeholder-shown + label.fullname {
  cursor: text;
  max-width: max-content;
  transform-origin: left bottom;
  transform: translate(0, 2.7rem) scale(1.5);
  margin-left: 15px;
  font-size: 12px;
  font-weight: bold;
}
::-webkit-input-placeholder {
  opacity: 0;
  transition: inherit;
}
.selected-flag > ul {
  height: 100px !important;
}
input.fullname:focus::-webkit-input-placeholder,
textarea.fullname:focus::-webkit-input-placeholder {
  opacity: 1;
}
input.fullname:not(:placeholder-shown) + label.fullname,
textarea.fullname:not(:placeholder-shown) + label.fullname,
input.fullname:focus + label.fullname,
textarea.fullname:focus + label.fullname {
  transform: translate(0, 1rem) scale(1);
  cursor: pointer;
  padding: 0px 5px;
  background: #fff;
  max-width: max-content;
  margin-left: 15px;
  z-index: 9;
  font-weight: bold;
}
.floating-label-field {
  display: flex !important;
  flex-flow: column-reverse !important;
  margin-bottom: 0.3em !important;
  height: 50px !important;
  position: relative !important;
}
.react-tel-input {
  margin-bottom: 17px !important;
  z-index: 999;
}
.react-tel-input > .flag-dropdown {
  border: 2px solid #848c9a !important;
  /* border-right: none !important; */
}
.react-tel-input .country-list .country-name {
  margin-right: 43px !important;
}
.react-tel-input > .form-control {
  width: 100% !important;
  font-size: 18px;
  color: #515c6f !important;
  border: 2px solid #848c9a !important;
  border-radius: 5px !important;
  height: 58px !important;
}
.selection-field {
  display: flex;
  flex-flow: column-reverse;
  margin-bottom: 0.3em;
  height: 58px;
  margin-top: -8px;
  margin-bottom: 13px;
}

/* Floatting Field */
/* Public Pages */
.spinner-border {
  color: #6a737b !important;
  position: absolute !important;
  margin-top: 0.5rem !important;
  z-index: 11;
}
.spinner-border-scan {
  color: #6a737b !important;
  position: absolute !important;
  margin-top: -12px !important;
  z-index: 11;
}

.spinner-control {
  height: 100%;
  margin: auto;
  width: 2rem;
}
.full-page-spinner {
  top: 50%;
  width: 2rem !important;
  height: 2rem !important;
}
.modal-header {
  min-height: 68px !important;
  align-items: center !important;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinner-border-scan {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border-scan {
  display: inline-block;
  width: 28px;
  height: 28px;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.spinner-border {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.show-field {
  z-index: 1000;
  position: absolute;
  right: 2%;
  top: 20%;
}
.error {
  color: red;
}
.body-text-size {
  font-size: 14px !important;
}
.password-checkbox {
  width: 0.813rem !important;
  height: 0.813rem !important;
  cursor: pointer;
  vertical-align: middle;
}
.cursor {
  cursor: pointer;
}
.details-li-decoration {
  display: flex;
  list-style: none;
}
.checkbox-size {
  width: 1.2rem !important;
  height: 1.2rem !important;
  margin-bottom: 0.5rem;
  cursor: pointer;
}
.text-align-checkbox {
  vertical-align: middle;
}
.country-list {
  padding: 15px;
  cursor: text;
  width: 100%;
  border: 2px solid #848c9a;
  border-radius: 5px;
  background: none;
  z-index: 5;
  height: 58px !important;
  background-color: white !important;
}
.bg-color-final {
  background-color: #f8f8f8;
}
.row-height {
  height: 8vh;
  display: flex;
  bottom: 0 !important;
  justify-content: center !important;
  align-items: center !important;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.hr-line {
  border-top: 0.5px solid;
  width: 100%;
  color: black;
}
.img-size {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.header-btn-size {
  width: 25px !important;
  height: 25px !important;
}
.hover-on {
  width: 42.5px;
  /* margin-top: -8px; */
  cursor: pointer;
  text-align: center;
}
.hover-on:hover {
  background-color: #6a737b79;
  cursor: pointer;
}
.label-text-pos {
  margin-top: 0.5rem !important;
}
.hide-radio {
  visibility: hidden;
  width: 0px !important;
}
.dropdown-control {
  max-width: 1rem !important;
}
.dropdown-menu {
  min-width: 2.8rem !important;
}
.dropdown-menu {
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid #8da0c1;
}

.dropdown-item {
  color: #515c6f;
}

.dropdown-divider {
  border-top: 1px solid #8da0c1;
}
.forms-position {
  position: absolute !important;
  transform: translate3d(0px, 25px, 0px) !important;
  top: 2px !important;
  left: -8px !important;
  will-change: transform !important;
}

/* Public Pages */
/* Auth Pages */
.list-group-item {
  display: flex;
  align-items: center;
  border: none !important;
}
li > i {
  margin-right: 1.5rem !important;
  font-size: 1.5rem !important;
  width: 1rem !important;
}
li > span {
  position: absolute;
  right: 10%;
}
.link-decoration {
  text-decoration: none;
  color: #515c6f;
}
.fa-check {
  color: green !important;
  font-size: 2.5em;
}
.ul-text {
  color: black;
  text-decoration: underline;
  font-size: 18px !important;
}
.receipt-text {
  font-size: 14px !important;
}
.receipt-heading {
  font-size: 24px !important;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid !important;
}
.table thead th {
  border-bottom: 1px solid !important;
}
.ul-text-danger {
  color: red;
  text-decoration: underline;
  font-size: 18px !important;
}
.logo-image {
  max-width: 90% !important;
  height: auto;
}
.selection-btn {
  color: #515c6f !important;
  background-color: white !important;
  border-color: #515c6f !important;
}

.selection-btn:focus {
  border: 1px dashed black;
}
.link-decoration:hover {
  text-decoration: none;
  color: #515c6f;
}
.icon-placement {
  margin-left: 5px;
}
.navbar-toggler {
  outline: none !important;
}
.navbar-toggler-icon {
  font-size: 1.5rem;
  width: 1.5rem !important;
  height: 1.5rem !important;
  font-weight: bold;
}
.toggle-icon-size {
  width: 25px !important;
  height: 25px !important;
  color: #515c6f;
}
.header-btn-size {
  width: 25px !important;
  height: 25px !important;
}
.hr-line {
  border-top: 0.5px solid;
  width: 100%;
  color: black;
}

.inbox-img-size {
  width: 3.5rem;
  height: 3.5rem;
}
.card-body {
  padding: 0 !important;
}
.card-text {
  text-align: justify;
}
.card-img {
  width: 5.5rem !important;
  height: 4.5rem !important;
  vertical-align: middle;
}
.card {
  border: none !important;
}
.btn-position {
  display: flex;
  justify-content: space-between;
}
.contact-message-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.small-image-size {
  padding-left: 0 !important;
}
.img-height {
  height: 100%;
}
.receipt-img {
  width: 5em;
  height: 5em;
}
.details-padding {
  padding-right: 10px !important;
}
.card-footer-text {
  font-size: 0.7rem !important;
  margin-left: 55%;
}
.head-text-align {
  cursor: pointer;
  text-align: end;
}
.icon-size {
  height: 2rem;
  width: 2rem;
}
.products-top-section {
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-color: #f8f8f8;
  min-height: 10rem;
  padding: 1.5rem;
}
.input-group-text {
  border: 1px solid #495057 !important;
  max-height: 38px !important;
  background-color: white !important;
  border-right: none !important;
}
.pdf-table-head {
  font-size: 14px !important;
}
.pdf-table-body {
  font-size: 12px !important;
}
.main-headings-helper {
  font-size: 1.3rem !important;
  color: #212529;
}
.main-headings {
  font-size: 2.79rem;
}
.item-detail-box {
  background-color: rgba(253, 252, 250, 0.9);

  border-radius: 4px;

  -moz-box-shadow: 6px 6px 0px rgba(141, 160, 193, 0.2);
  -webkit-box-shadow: 6px 6px 0px rgba(141, 160, 193, 0.2);
  box-shadow: 6px 6px 0px rgba(141, 160, 193, 0.2);
}
.sticky-left {
  right: 45px;
}
.sticky-right {
  left: 38px;
  z-index: 11111;
}
.bg-color {
  background-color: rgba(141, 160, 193, 0.2);
  width: 100%;
  height: 21.68rem !important;
}
.details-type-title {
  padding-left: 15px;
}
.desc {
  font-size: 13px !important;
}
.properties-height {
  min-height: 15.56rem !important;
}
.selected-btn {
  color: #515c6f !important;
  background-color: white !important;
  border-color: #515c6f !important;
}
.buttons-list {
  width: 100%;
  height: 18em !important;
  background-color: #e8ecf3 !important;
  justify-content: center;
  align-items: center;
}

.btn-info {
  background-color: rgba(49, 132, 253, 1) !important;
}

.selected-btn:focus,
.selected-btn:active {
  border: 2px dashed #515c6f !important;
}
.btn-outline-secondary:active,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}
.reset-page-height {
  height: 71vh;
}
.message-group-feed {
  height: calc(110vw - 100px) !important;
  overflow-y: scroll;
  scrollbar-width: none;
  overflow-x: hidden;
}

.couple-ring-btn {
  padding-left: 30px;
  padding-right: 30px;
}

@media (max-width: 767px) {
  .w-sm-100 {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .message-group-feed {
    height: 35vw !important;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
  }
}
.message-feed {
  height: calc(100vw - 100px) !important;
  overflow-y: scroll;
  scrollbar-width: none;
  overflow-x: hidden;
}
::-webkit-scrollbar-thumb {
  background: #c1c1c1;
}
::-webkit-scrollbar {
  width: 0.6em;
}
.bg-unread {
  background-color: #ededed;
  padding: 10px 10px 0px 10px;
}
.bg-read {
  padding: 10px 10px 0px 10px;
}
.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.row-height {
  height: 8vh;
  display: flex;
  bottom: 0 !important;
  justify-content: center !important;
  align-items: center !important;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.message-img-size {
  width: 5.5rem !important;
  height: 4.5rem !important;
}
.icon-positioning-letter {
  text-align: end;
  margin-right: 1rem;
}

.message-footer {
  position: relative;
}
.producer-field {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.checkingwrap {
  word-wrap: break-word;
}
.keep-it-bottom {
  height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column-reverse;
}
.booked-body {
  /* width: 100%; */
  min-height: 200px;
  background-color: #eae7e7;
  position: relative;
  margin-bottom: 48px !important;
}
.check-circle-style {
  font-size: 4.625rem !important;
  color: green;
  /* width: 100% !important; */
  /* text-align: end; */
  position: absolute;
  right: 5%;
  bottom: -13%;
}
.card-mid-title {
  font-size: 18px !important;
}
.card-mid-body {
  font-size: 14px !important;
  margin-bottom: 3rem !important;
}
.card-end-body {
  font-size: 14px !important;
}
.ring-properties {
  font-size: 0.9rem !important;
}
.sidebar-page-height {
  height: 65vh;
  width: 100%;
  display: flex;
  align-items: center;
}
.sidebar-spinner-control {
  margin: auto;
  width: 2rem;
}

/* Auth Pages */

/* Media Queries */

/* small devices */
@media (max-width: 768px) {
  .page-height {
    min-height: 71vh !important;
  }

  .img-height {
    height: 90%;
  }
  .main-headings {
    font-size: 1.73rem !important;
  }
  .sticky-left {
    right: 0px !important;
  }
  .sticky-right {
    left: 0px !important;
    z-index: 11111;
  }
  .modal {
    padding: 0 !important;
    min-width: 100% !important;
    overflow: hidden !important;
  }
  .modal::-webkit-scrollbar {
    display: none;
  }
  .modal .modal-dialog {
    width: 100%;
    max-width: none;
    margin: 0;
    overflow: hidden !important;
  }
  .modal-dialog::-webkit-scrollbar {
    display: none;
  }
  .modal-open::-webkit-scrollbar {
    display: none;
  }
  .modal .modal-open {
    overflow: hidden !important;
  }

  .modal .modal-content {
    height: 100vh !important;
    border: 0;
    border-radius: 0;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .modal-content::-webkit-scrollbar {
    display: none;
  }
  .modal-header-part {
    justify-content: space-between;
    width: 67% !important;
  }

  /* .modal-content {
    padding: 0px !important;
    margin: 0px !important;
    height: 100vh !important;
    border: none !important;
    border-radius: 0% !important;
    outline: 0 !important;
    overflow-x: hidden !important;
  }
  .modal-dialog {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  } */
  .ring-properties {
    font-size: 0.7rem !important;
  }
}

/* Large Devices */
@media (min-width: 768px) {
  .message-feed {
    height: 27vw !important;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
  }
  .modal-header-part {
    justify-content: space-between;
    width: 62% !important;
  }
  .generate-pdf-btn-size {
    width: 14rem !important;
  }
}

/* Media Queries */
